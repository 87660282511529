<template>
  <v-card
    v-if="brand"
    flat
    class="px-3 mx-sm-5"
  >
    <v-alert
      dismissible
      text
      outlined
      type="error"
      v-if="brandErrorMessage"
      class="mt-4 col-12 text-center"
    >
      {{ brandErrorMessage }}
    </v-alert>
    <v-card-title>{{ $t('emailNotifications') }}</v-card-title>
    <v-col cols="9">
      <v-text-field
        v-model="brand.emailNotifications.quote"
        :label="$t('NotificationsQuote')"
        append-icon="fas fa-at"
        rounded
        outlined
        :rules="[(value) => (isEmail(value) || value.trim() === '') || $t('PleaseEnterAValidEmail') ]"
      >
      </v-text-field>
      <v-text-field
        v-model="brand.emailNotifications.invoice"
        :label="$t('NotificationsInvoice')"
        append-icon="fas fa-at"
        rounded
        outlined
        :rules="[(value) => (isEmail(value) || value.trim() === '') || $t('PleaseEnterAValidEmail') ]"
      >
      </v-text-field>
      <v-text-field
        v-model="brand.emailNotifications.subscriptionInvoice"
        :label="$t('NotificationsSubscriptionInvoice')"
        append-icon="fas fa-at"
        rounded
        outlined
        :rules="[(value) => (isEmail(value) || value.trim() === '') || $t('PleaseEnterAValidEmail') ]"
      >
      </v-text-field>
    </v-col>
    <v-card-actions>
      <v-btn
        class="text-none"
        color="error"
        @click="getBrand"
      >
        <v-icon small class="mr-1">
          fas fa-ban
        </v-icon>{{ $t('Cancel') }}
      </v-btn>
      <v-btn
        class="text-none"
        color="success"
        @click="modifyBrandEmailNotifications"
      >
        <v-icon small class="mr-1">
          fas fa-check
        </v-icon>{{ $t('Save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import isEmail from 'validator/lib/isEmail';
import { ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageBrandNotifications.i18n.json');

export default {
  name: 'PageBrandNotifications',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      loading: false,
      brand: null,
      emailsNotificationsList: [],
      emailNotificationsInput: '',
      isEmail,
      brandErrorMessage: null,
    };
  },
  created() {
    /**
     * GET BRAND
     */
    this.getBrand();
  },
  methods: {
    /**
     * GET BRAND
     */
    getBrand() {
      this.loading = true;
      this.$apiInstance
        .getAdminBrand(this.$route.params.brandUUID)
        .then(
          (brandData) => {
            this.brand = brandData;
          },
          (error) => {
            /**
             * ERROR GET BRAND
             */
            this.brandErrorMessage = ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    modifyBrandEmailNotifications() {
      const emailNotifications = new this.$BcmModel.EmailNotifications(
        this.brand.emailNotifications.quote,
        this.brand.emailNotifications.invoice,
        this.brand.emailNotifications.subscriptionInvoice,
      );
      this.$apiInstance.modifyBrandEmailNotifications(this.$route.params.brandUUID, emailNotifications).then(() => {
        this.$notification.notify('SUCCESS',this.$t('EmailListSuccessfullyUpdated'));
      },(error) => {
        /**
             * ERROR MODIFY BRAND
             */
        this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
      });
    },
  },
};
</script>
