var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.brandHomologationRules ? _c('v-card', {
    staticClass: "px-3 mx-sm-5",
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t("HomologationRules")))]), _c('div', {
    staticClass: "text-right"
  }), _c('v-card-title', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.dialogAddHomologationRules = true;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-plus-circle ")]), _vm._v(_vm._s(_vm.$t("addNewRules")) + " ")], 1), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('v-card-text', [_c('v-data-table', {
    attrs: {
      "show-select": "",
      "single-select": false,
      "headers": _vm.headersHomologationRules,
      "items": _vm.indexedItems,
      "search": _vm.search
    },
    scopedSlots: _vm._u([_vm.selectedRules.length > 0 ? {
      key: `header.technology`,
      fn: function () {
        return [_c('div', {
          staticClass: "text-left"
        }, [_c('a', {
          staticClass: "orange--text text-none",
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              _vm.dialogDeleteHomologationRules = true;
            }
          }
        }, [_c('v-icon', {
          staticClass: "orange--text mb-1 mr-1",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-trash ")]), _vm._v(" " + _vm._s(_vm.$t('delete')) + " ")], 1)])];
      },
      proxy: true
    } : null, _vm.selectedRules.length > 0 ? {
      key: `header.material`,
      fn: function () {
        return undefined;
      },
      proxy: true
    } : null, _vm.selectedRules.length > 0 ? {
      key: `header.supplier`,
      fn: function () {
        return undefined;
      },
      proxy: true
    } : null, {
      key: `item.technology`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.technology) + " ")];
      }
    }, {
      key: `item.material`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.material.split('_').join(' ').toUpperCase()) + " ")];
      }
    }, {
      key: `item.supplier`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.supplier.name) + " ")];
      }
    }], null, true),
    model: {
      value: _vm.selectedRules,
      callback: function ($$v) {
        _vm.selectedRules = $$v;
      },
      expression: "selectedRules"
    }
  })], 1)], 1) : _vm._e(), _c('v-dialog', {
    attrs: {
      "width": "600",
      "persistent": ""
    },
    model: {
      value: _vm.dialogAddHomologationRules,
      callback: function ($$v) {
        _vm.dialogAddHomologationRules = $$v;
      },
      expression: "dialogAddHomologationRules"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('addSupplierForAutoHomologation')) + " ")]), _c('v-divider', {
    staticClass: "mx-4 mb-4"
  }), _c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('v-form', {
    ref: "homologationRulesForm"
  }, [_c('v-select', {
    attrs: {
      "menu-props": {
        offsetY: true
      },
      "label": _vm.$t('Technologies'),
      "items": _vm.availableTechnologies,
      "outlined": "",
      "rules": [function (value) {
        return !!value || _vm.$t('nameRules');
      }]
    },
    on: {
      "change": function ($event) {
        return _vm.getMaterial(_vm.selectedTechnology);
      }
    },
    model: {
      value: _vm.selectedTechnology,
      callback: function ($$v) {
        _vm.selectedTechnology = $$v;
      },
      expression: "selectedTechnology"
    }
  }), _vm.selectedTechnology ? _c('v-select', {
    attrs: {
      "menu-props": {
        offsetY: true
      },
      "label": _vm.$t('Material'),
      "items": _vm.availableMaterials,
      "chips": "",
      "multiple": "",
      "outlined": "",
      "rules": [function (value) {
        return !!value || _vm.$t('nameRules');
      }]
    },
    on: {
      "change": _vm.getSupplier
    },
    scopedSlots: _vm._u([{
      key: "prepend-item",
      fn: function () {
        return [_c('v-list-item', {
          attrs: {
            "ripple": ""
          },
          on: {
            "click": _vm.toggleAllMaterial,
            "change": _vm.getSupplier
          }
        }, [_c('v-list-item-action', [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icon) + " ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t('selectAll')) + " ")])], 1)], 1), _c('v-divider', {
          staticClass: "mt-2"
        })];
      },
      proxy: true
    }], null, false, 3932652432),
    model: {
      value: _vm.selectedMaterial,
      callback: function ($$v) {
        _vm.selectedMaterial = $$v;
      },
      expression: "selectedMaterial"
    }
  }) : _vm._e(), _vm.selectedMaterial && _vm.selectedMaterial.length > 0 && _vm.suppliers ? _c('v-select', {
    attrs: {
      "menu-props": {
        offsetY: true
      },
      "label": _vm.$t('Supplier'),
      "items": _vm.suppliers,
      "item-text": function (item) {
        return item.name;
      },
      "item-value": function (item) {
        return item._id;
      },
      "outlined": "",
      "rules": [function (value) {
        return !!value || _vm.$t('nameRules');
      }]
    },
    model: {
      value: _vm.selectedSupplier,
      callback: function ($$v) {
        _vm.selectedSupplier = $$v;
      },
      expression: "selectedSupplier"
    }
  }) : _vm._e()], 1)], 1), _c('v-divider', {
    staticClass: "mx-4"
  }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.closeDialogAndResetData
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success"
    },
    on: {
      "click": _vm.addBrandHomologationRules
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Save')) + " ")], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "width": "800",
      "persistent": ""
    },
    model: {
      value: _vm.modalSummary,
      callback: function ($$v) {
        _vm.modalSummary = $$v;
      },
      expression: "modalSummary"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('summaryAddedHomologationRules')) + " ")]), _c('v-divider', {
    staticClass: "mx-4 mb-4"
  }), _c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('v-list', {
    staticClass: "pb-0",
    attrs: {
      "rounded": "",
      "dense": ""
    }
  }, [_c('v-list-item-group', {
    attrs: {
      "color": "primary"
    }
  }, _vm._l(_vm.summaryHomologationRules, function (item, i) {
    return _c('v-list-item', {
      key: i
    }, [_c('v-list-item-icon', [_c('v-icon', {
      staticClass: "mr-1",
      attrs: {
        "x-small": "",
        "color": "success"
      }
    }, [_vm._v(" fas fa-check ")])], 1), _c('v-list-item-content', [_vm._v(" " + _vm._s(_vm.$t("SuccessAddHomologationRules", {
      technologyName: item.technology,
      materialName: item.material.split('_').join(' ').toUpperCase()
    })) + " ")])], 1);
  }), 1)], 1), _c('v-list', {
    staticClass: "pt-0",
    attrs: {
      "rounded": "",
      "dense": ""
    }
  }, [_c('v-list-item-group', {
    attrs: {
      "color": "primary"
    }
  }, _vm._l(_vm.materialCantBeSend, function (item, i) {
    return _c('v-list-item', {
      key: i
    }, [_c('v-list-item-icon', [_c('v-icon', {
      staticClass: "mr-1",
      attrs: {
        "x-small": "",
        "color": "error"
      }
    }, [_vm._v(" fas fa-times ")])], 1), _c('v-list-item-content', [_vm._v(" " + _vm._s(_vm.$t("errorAddHomologationRules", {
      technologyName: item.technology,
      materialName: item.material.split('_').join(' ').toUpperCase()
    })) + " ")])], 1);
  }), 1)], 1)], 1), _c('v-divider', {
    staticClass: "mx-4 mt-4"
  }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success"
    },
    on: {
      "click": function ($event) {
        _vm.modalSummary = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Ok')) + " ")], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "500",
      "primary-title": ""
    },
    model: {
      value: _vm.dialogDeleteHomologationRules,
      callback: function ($$v) {
        _vm.dialogDeleteHomologationRules = $$v;
      },
      expression: "dialogDeleteHomologationRules"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(" " + _vm._s(_vm.$t("deleteDialogTitle")) + " ")]), _c('v-divider', {
    staticClass: "mt-1"
  }), _c('div', [_c('v-card', {
    staticClass: "pa-4",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" fas fa-exclamation-triangle ")]), _vm._v(" " + _vm._s(_vm.$tc("deleteDialogBody")) + " ")], 1)], 1), _c('v-divider', {
    staticClass: "mb-1"
  }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "info text-none",
    on: {
      "click": function ($event) {
        _vm.dialogDeleteHomologationRules = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times-circle ")]), _vm._v(" " + _vm._s(_vm.$t("deleteDialogClose")) + " ")], 1), _c('v-btn', {
    staticClass: "orange text-none",
    attrs: {
      "dark": ""
    },
    on: {
      "click": function ($event) {
        _vm.removeHomologationRule(_vm.selectedRules);
        _vm.dialogDeleteHomologationRules = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t("deleteDialogValidate")) + " ")], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }