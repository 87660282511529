<template>
  <div>
    <v-card
      v-if="brandHomologationRules"
      flat
      class="px-3 mx-sm-5"
    >
      <v-card-title>{{ $t("HomologationRules") }}</v-card-title>
      <div class="text-right"></div>
      <v-card-title>
        <v-btn
          color="primary"
          class="text-none"
          @click="dialogAddHomologationRules = true"
        >
          <v-icon small class="mr-2">
            fas fa-plus-circle
          </v-icon>{{ $t("addNewRules") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="fas fa-search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-model="selectedRules"
          show-select
          :single-select="false"
          :headers="headersHomologationRules"
          :items="indexedItems"
          :search="search"
        >
          <template
            v-slot:[`header.technology`]
            v-if="selectedRules.length > 0"
          >
            <div class="text-left">
              <a
                class="orange--text text-none"
                @click.stop="dialogDeleteHomologationRules = true"
              >
                <v-icon small class="orange--text mb-1 mr-1">
                  fas fa-trash
                </v-icon>
                {{ $t('delete') }}
              </a>
            </div>
          </template>
          <template
            v-slot:[`header.material`]
            v-if="selectedRules.length > 0"
          >
          </template>
          <template
            v-slot:[`header.supplier`]
            v-if="selectedRules.length > 0"
          >
          </template>
          <template v-slot:[`item.technology`]="{ item }">
            {{ item.technology }}
          </template>
          <template v-slot:[`item.material`]="{ item }">
            {{ item.material.split('_').join(' ').toUpperCase() }}
          </template>
          <template v-slot:[`item.supplier`]="{ item }">
            {{ item.supplier.name }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <!-- DIALOG ADD HOMOLOGATION RULES -->
    <!-- DIALOG ADD HOMOLOGATION RULES -->
    <!-- DIALOG ADD HOMOLOGATION RULES -->
    <v-dialog
      v-model="dialogAddHomologationRules"
      width="600"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ $t('addSupplierForAutoHomologation') }}
        </v-card-title>
        <v-divider class="mx-4 mb-4"></v-divider>
        <v-card-text class="pb-0">
          <v-form ref="homologationRulesForm">
            <v-select
              :menu-props="{ offsetY: true }"
              :label="$t('Technologies')"
              :items="availableTechnologies"
              v-model="selectedTechnology"
              @change="getMaterial(selectedTechnology)"
              outlined
              :rules="[
                value => !!value || $t('nameRules'),
              ]"
            ></v-select>
            <v-select
              v-if="selectedTechnology"
              :menu-props="{ offsetY: true }"
              :label="$t('Material')"
              :items="availableMaterials"
              v-model="selectedMaterial"
              @change="getSupplier"
              chips
              multiple
              outlined
              :rules="[
                value => !!value || $t('nameRules'),
              ]"
            >
              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @click="toggleAllMaterial"
                  @change="getSupplier"
                >
                  <v-list-item-action>
                    <v-icon>
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('selectAll') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
            <v-select
              v-if="selectedMaterial && selectedMaterial.length > 0 && suppliers"
              :menu-props="{ offsetY: true }"
              :label="$t('Supplier')"
              :items="suppliers"
              :item-text="item => item.name"
              :item-value="item => item._id"
              v-model="selectedSupplier"
              outlined
              :rules="[
                value => !!value || $t('nameRules'),
              ]"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-none"
            color="error"
            @click="closeDialogAndResetData"
          >
            <v-icon small class="mr-1">
              fas fa-ban
            </v-icon>
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
            class="text-none"
            color="success"
            @click="addBrandHomologationRules"
          >
            <v-icon small class="mr-1">
              fas fa-check
            </v-icon>
            {{ $t('Save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="modalSummary"
      width="800"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ $t('summaryAddedHomologationRules') }}
        </v-card-title>
        <v-divider class="mx-4 mb-4"></v-divider>
        <v-card-text class="pb-0">
          <v-list
            rounded
            dense
            class="pb-0"
          >
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, i) in summaryHomologationRules" :key="i">
                <v-list-item-icon>
                  <v-icon
                    x-small
                    color="success"
                    class="mr-1"
                  >
                    fas fa-check
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  {{ $t("SuccessAddHomologationRules", { technologyName: item.technology ,materialName: item.material.split('_').join(' ').toUpperCase() }) }}
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-list
            rounded
            dense
            class="pt-0"
          >
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, i) in materialCantBeSend" :key="i">
                <v-list-item-icon>
                  <v-icon
                    x-small
                    color="error"
                    class="mr-1"
                  >
                    fas fa-times
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  {{ $t("errorAddHomologationRules", { technologyName: item.technology ,materialName: item.material.split('_').join(' ').toUpperCase() }) }}
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-divider class="mx-4 mt-4"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-none"
            color="success"
            @click="modalSummary = false"
          >
            <v-icon small class="mr-1">
              fas fa-check
            </v-icon>
            {{ $t('Ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG DELETE HOMOLOGATION RULES -->
    <!-- DIALOG DELETE HOMOLOGATION RULES -->
    <v-dialog
      v-model="dialogDeleteHomologationRules"
      max-width="500"
      primary-title
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t("deleteDialogTitle") }}
        </v-card-title>
        <v-divider class="mt-1"></v-divider>

        <div>
          <v-card elevation="0" class="pa-4">
            <v-icon color="info" class="mr-2">
              fas fa-exclamation-triangle
            </v-icon>
            {{ $tc("deleteDialogBody") }}
          </v-card>
        </div>
        <v-divider class="mb-1"></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="info text-none" @click="dialogDeleteHomologationRules = false">
            <v-icon small class="mr-1">
              fas fa-times-circle
            </v-icon>
            {{ $t("deleteDialogClose") }}
          </v-btn>
          <v-btn
            @click="removeHomologationRule(selectedRules); dialogDeleteHomologationRules = false"
            dark
            class="orange text-none"
          >
            <v-icon small class="mr-1">
              fas fa-check
            </v-icon>
            {{ $t("deleteDialogValidate") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageBrandHomologationRules.i18n.json');

export default {
  name: 'PageBrandHomologationRules',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      brand: null,
      brandHomologationRules: null,
      suppliers: null,
      suppliersData: null,
      selectedSupplier: null,
      selectedTechnology: null,
      selectedMaterial: null,
      selectedRules: [],
      singleSelect: false,
      technologiesAndMaterials: null,
      selectedMaterials: [],
      headersHomologationRules: [],
      availableTechnologies: [],
      availableMaterials: [],
      materialCantBeSend: [],
      search: '',
      modalSummary: false,
      summaryHomologationRules: [],
      dialogAddHomologationRules: false,
      dialogDeleteHomologationRules: false,
      coupleTechnoMaterialAlreadyExist: false,
    };
  },
  mounted() {
    this.headersHomologationRules = [
      {
        text: this.$t('technology'),
        value: 'technology',
        align: 'center',
        sortable: false,
      },
      {
        text: this.$t('material'),
        value: 'material',
        align: 'center',
      },
      {
        text: this.$t('supplier'),
        value: 'supplier',
        align: 'center',
      },
    ];
  },
  created() {
    this.getBrand();
  },
  computed: {
    allMaterials() {
      return this.selectedMaterial.length === this.availableMaterials.length;
    },
    someMaterials() {
      return this.selectedMaterial.length > 0 && !this.allMaterials;
    },
    icon() {
      if (this.allMaterials) return 'fas fa-check-square';
      if (this.someMaterials) return 'fas fa-minus-square';
      return 'far fa-square';
    },
    indexedItems() {
      return this.brandHomologationRules.map((item, index) => ({
        id: index,
        ...item
      }));
    }
  },
  methods: {
    getBrand() {
      this.$apiInstance
        .getAdminBrand(this.$route.params.brandUUID)
        .then(
          (brandData) => {
            this.brand = brandData;
            this.brandHomologationRules = brandData.homologationRules;
            this.getAllTechnologiesAndMaterials();
          },
          (error) => {
            ApiErrorParser.parse(error);
          }
        );
    },
    getAllTechnologiesAndMaterials() {
      this.$apiInstance
        .getAllTechnologiesAndMaterialsCompatibilities()
        .then((technologiesAndMaterials) => {
                this.availableTechnologies = [];
                this.technologiesAndMaterials = technologiesAndMaterials;
                this.technologiesAndMaterials.forEach((technologyCompatibilities) => {
                  const numberOfMaterials = technologyCompatibilities.materials.length;
                  let numberOfMaterialsUsed = 0;
                  this.brandHomologationRules.forEach((homologationRule) => {
                    if(homologationRule.technology === technologyCompatibilities.technology) {
                      numberOfMaterialsUsed += 1;
                    }
                  });
                  if (numberOfMaterialsUsed < numberOfMaterials) {
                    this.availableTechnologies.push(technologyCompatibilities.technology);
                  }
                });

              },
              /**
               * ERROR GET ALL TECHNOLOGIES AND MATERIALS
               */
              (error) => {
                this.$notification.notify(
                  'DANGER',
                  this.$t(ApiErrorParser.parse(error))
                );
              });
    },
    getMaterial(technology) {
      this.availableMaterials = [];
      this.selectedMaterial = [];
      this.selectedSupplier = null;
      for (let index = 0; this.technologiesAndMaterials.length > index; index++) {
        if(this.technologiesAndMaterials[index].technology === technology) {
          this.technologiesAndMaterials[index].materials.forEach((materialCompatibilities) => {
            let materialFound = false;
            this.brandHomologationRules.forEach((homologationRule) => {
              if (homologationRule.technology === technology && homologationRule.material === materialCompatibilities.material) {
                materialFound = true;
                return;
              }
            });
            if (!materialFound) {
              this.availableMaterials.push({ 
                text: this.$t(materialCompatibilities.material),
                value: materialCompatibilities.material
              });
            }
          });
          break;
        }
      }
    },
    getSupplier() {
      this.$apiInstance
        .getAllSuppliers()
        .then(
          (suppliersData) => {
            this.suppliersData = suppliersData;
            this.suppliers = [];
            this.selectedSupplier = null;
            this.selectedMaterials = [];
            for (let i = 0; i < this.selectedMaterial.length; i++) {
              this.selectedMaterials.push(this.selectedMaterial[i]);
            }
            this.selectedMaterials.forEach((material) => {
              for (const supplier of this.suppliersData) {
                if(
                  supplier.public && 
                  this.brand.features.accessToBCMNetwork &&
                  supplier?.technologies?.[this.selectedTechnology]?.includes(material)
                ) {
                  this.suppliers.push({
                    name: supplier.name,
                    _id: supplier._id
                  });
                }
              }
            });
          },
          (error) => {
            ApiErrorParser.parse(error);
          }
        );
    },
    async addBrandHomologationRules() {
      this.summaryHomologationRules = [];
      this.materialCantBeSend = [];
      const materialToSend = [];
      const supplierObject = {
        name: this.suppliers.name,
        _id: this.selectedSupplier
      };
      for (let index = 0; this.brandHomologationRules.length > index; index++) {
        if (this.brandHomologationRules[index].technology.includes(this.selectedTechnology)) {
          if (this.brandHomologationRules[index].material.includes(this.selectedMaterial)) {
            this.coupleTechnoMaterialAlreadyExist = true;
          }
        }
      }
      this.selectedMaterials.forEach((material) => {
        for (const supplier of this.suppliersData) {
          if (supplier._id === this.selectedSupplier) {
            if (supplier.technologies && supplier.technologies[this.selectedTechnology] && supplier.technologies[this.selectedTechnology].includes(material)) {
              materialToSend.push(material);
            } else {
              this.materialCantBeSend.push({
                technology: this.selectedTechnology,
                material: material
              });
            }
          }
        }
      });
      if (this.$refs.homologationRulesForm.validate()) {
        if (!this.coupleTechnoMaterialAlreadyExist) {
          const homologationRulesArray = [...this.brandHomologationRules];
          materialToSend.forEach((material) => {
            const homologationRules = new this.$BcmModel.HomologationRules(
              supplierObject,
              this.selectedTechnology,
              material
            );
            this.summaryHomologationRules.push({
              technology: this.selectedTechnology,
              material: material
            });
            homologationRulesArray.push(homologationRules);
          });
          await this.$apiInstance
            .modifyBrandHomologationRules(
              this.$route.params.brandUUID,
              homologationRulesArray
            )
            .then(
              () => {
                this.$notification.notify('SUCCESS',this.$t('rulesSuccessfullyAdded'));
                this.closeDialogAndResetData();
                this.modalSummary = true;
              },
              (error) => {
                this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
              }
            );
        } else {
          this.$notification.notify('DANGER',this.$t('technoAndMaterialAlreadyExist'));
          this.$refs.homologationRulesForm.reset();
          this.coupleTechnoMaterialAlreadyExist = false;
        }
      } 
    },
    removeHomologationRule(item) {
      for (let i = 0; i < item.length; i++) {
        const index = this.brandHomologationRules.findIndex(rule => {
          return rule.technology === item[i].technology && rule.material === item[i].material;
        });
        this.brandHomologationRules.splice(index, 1);
      }
      this.$apiInstance
        .modifyBrandHomologationRules(
          this.$route.params.brandUUID,
          this.brandHomologationRules
        )
        .then(
          () => {
            this.getBrand();
            this.$notification.notify('SUCCESS',this.$t('rulesSuccessfullyDeleted'));
            this.selectedRules = [];
          },
          (error) => {
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    toggleAllMaterial() {
      if (this.allMaterials) {
        this.selectedMaterial = [];
      } else {
        this.selectedMaterial = [];
        for (const availableMaterial of this.availableMaterials) {
          this.selectedMaterial.push(availableMaterial.value);
        }
      }
    },
    closeDialogAndResetData() {
      this.$refs.homologationRulesForm.reset();
      this.suppliersData = null;
      this.suppliers = null;
      this.dialogAddHomologationRules = false;
      this.coupleTechnoMaterialAlreadyExist = false;
      this.getBrand();
    },
  },
};
</script>
