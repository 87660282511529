var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.brand ? _c('v-card', {
    staticClass: "px-3 mx-sm-5",
    attrs: {
      "flat": ""
    }
  }, [_vm.brandErrorMessage ? _c('v-alert', {
    staticClass: "mt-4 col-12 text-center",
    attrs: {
      "dismissible": "",
      "text": "",
      "outlined": "",
      "type": "error"
    }
  }, [_vm._v(" " + _vm._s(_vm.brandErrorMessage) + " ")]) : _vm._e(), _c('v-card-title', [_vm._v(_vm._s(_vm.$t('emailNotifications')))]), _c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('NotificationsQuote'),
      "append-icon": "fas fa-at",
      "rounded": "",
      "outlined": "",
      "rules": [function (value) {
        return _vm.isEmail(value) || value.trim() === '' || _vm.$t('PleaseEnterAValidEmail');
      }]
    },
    model: {
      value: _vm.brand.emailNotifications.quote,
      callback: function ($$v) {
        _vm.$set(_vm.brand.emailNotifications, "quote", $$v);
      },
      expression: "brand.emailNotifications.quote"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('NotificationsInvoice'),
      "append-icon": "fas fa-at",
      "rounded": "",
      "outlined": "",
      "rules": [function (value) {
        return _vm.isEmail(value) || value.trim() === '' || _vm.$t('PleaseEnterAValidEmail');
      }]
    },
    model: {
      value: _vm.brand.emailNotifications.invoice,
      callback: function ($$v) {
        _vm.$set(_vm.brand.emailNotifications, "invoice", $$v);
      },
      expression: "brand.emailNotifications.invoice"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('NotificationsSubscriptionInvoice'),
      "append-icon": "fas fa-at",
      "rounded": "",
      "outlined": "",
      "rules": [function (value) {
        return _vm.isEmail(value) || value.trim() === '' || _vm.$t('PleaseEnterAValidEmail');
      }]
    },
    model: {
      value: _vm.brand.emailNotifications.subscriptionInvoice,
      callback: function ($$v) {
        _vm.$set(_vm.brand.emailNotifications, "subscriptionInvoice", $$v);
      },
      expression: "brand.emailNotifications.subscriptionInvoice"
    }
  })], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.getBrand
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(_vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success"
    },
    on: {
      "click": _vm.modifyBrandEmailNotifications
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(_vm._s(_vm.$t('Save')) + " ")], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }